<template>
  <template-table
    :column="column"
    :data="patients"
    :paging="paging"
    :tableAction="false"
    :selectAction="showSelect"
    :searchAction="false"
    :pagingAction="showPaging"
    @select="select"
    @sortBy="sortRequest"
  >
    <template v-slot:body="{ item }">
      <td>
        <div class="d-flex align-items-center">
          <avatar
            size="40px"
            :text="item.updaterName"
            :src="item.image.url"
            :rounded="true"
          />
          <div class="d-flex flex-column ml-5">
            <p class="mb-0 module-list-page__body__updater-name">
              {{ item.fullName }}
            </p>
            <p class="mt-2 mb-0 module-list-page__body__updater-code">
              {{ item.code ? `Mã số: ${item.code}` : null
              }}{{ item.packageCode ? `, ${item.packageCode}` : null }}
            </p>
          </div>
        </div>
      </td>
      <td v-if="showActions" style="width: 20px">
        <action-dropdown
          v-if="!item.isNew"
          :value="item"
          :show_copy="false"
          :show_view="false"
          :show_delete="false"
          :show_edit="false"
          boundary="window"
        >
          <b-dropdown-text
            tag="div"
            class="navi-item cursor-pointer"
            @click="changeGroup(item)"
          >
            <a class="navi-link text-primary">
              <span class="menu-icon svg-icon svg-icon-sm">
                <inline-svg
                  class="svg-icon"
                  src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                />
              </span>
              <span class="navi-text text-primary">Thay đổi nhóm</span>
            </a>
          </b-dropdown-text>
        </action-dropdown>
      </td>
        <td  style="width: 20px">
              <action-dropdown
                :value="item"
                :show_copy="false"
                :show_view="false"
                :show_edit="false"
                :show_delete="false"
              >
                <b-dropdown-text
              
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="viewDashboard(item)"
                  

                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/eye.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Xem chi tiết </span
                    >
                  </a>
                </b-dropdown-text>
               
              </action-dropdown>
            </td>
       
      <td class="text-center">{{ item.userCode }}</td>
      <td class="text-center">{{ item.age }}</td>
      <td class="text-center">{{ item.tip }}</td>
      <td class="text-center">{{ item.phoneNumber }}</td>
      <td class="text-center">
        {{ convertTimestampToDate(item.startDate) }}
      </td>
      
      <td class="text-center">{{ convertTimestampToDate(item.endDate) }}</td>
      <td v-if="!showActions && !isDetail" class="text-center">
        <a
          class="d-block a-link"
          href="javascript:void(0)"
          @click="openModalMapping(item)"
        >
          {{ item.mappingValue }}/11
        </a>
      </td>
      <td class="text-center">
        <span
          v-if="!isAddPatient && item.isNew"
          class="text-danger cursor-pointer"
          @click="deletePatient(item)"
        >
          <inline-svg
            class="svg-icon"
            src="/media/svg/icons/Neolex/Basic/trash-2.svg"
          />
        </span>
      </td>
    </template>
  </template-table>
</template>
<script>
export default {
  name: 'TablePatient',
  props: {
    showPaging: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: null,
    },
    patients: {
      type: Array,
      default: () => [],
    },
    paging: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isAddPatient() {
      return this.type === 'add-patient' || false;
    },
    isDetail() {
      return this.$route.name === 'coach_detail';
    
    },
    column() {
      if (this.showActions) {
        return [
          {
            label: 'Khách hàng',
            class: 'text-left',
            key: 'fullName',
            sortable: true,
            style: {
              width: '30%',
            },
          },
          {
            label: '...',
            class: 'text-left',
            key: 'actionDropdown',
            sortable: false,
             style: {
              width: '30%',
            },
          },
          {
            label: 'Usercode',
            key: 'usercode',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
          {
            label: 'Tuổi',
            key: 'age',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
          {
            label: 'ĐTĐ Type',
            key: 'tip',
            sortable: false,
            class: 'text-center',
            style: {
              width: '10%',
            },
          },
          {
            label: 'Số điện thoại',
            key: 'phoneNumber',
            sortable: false,
            class: 'text-center',
            style: {
              width: '10%',
            },
          },
          {
            label: 'Ngày bắt đầu',
            key: 'startDate',
            sortable: false,
            class: 'text-center',
            style: {
              width: '15%',
            },
          },
          {
            label: 'Ngày kết thúc',
            key: 'endDate',
            sortable: false,
            class: 'text-center',
            style: {
              width: '15%',
            },
          },
          {
            label: '',
            key: 'action',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
        ];
      }

      if (this.isDetail) {
        return [
          {
            label: 'Khách hàng',
            class: 'text-left',
            key: 'name',
            sortable: true,
            style: {
              width: '30%',
            },
          },
          {
            label: '',
            key: 'usercode',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
          {
            label: 'UserCode',
            key: 'usercode',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
          {
            label: 'Tuổi',
            key: 'age',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
          {
            label: 'ĐTĐ Tip',
            key: 'tip',
            sortable: false,
            class: 'text-center',
            style: {
              width: '10%',
            },
          },
          {
            label: 'Số điện thoại',
            key: 'phoneNumber',
            sortable: false,
            class: 'text-center',
            style: {
              width: '10%',
            },
          },
          {
            label: 'Ngày bắt đầu',
            key: 'startDate',
            sortable: false,
            class: 'text-center',
            style: {
              width: '15%',
            },
          },
          {
            label: 'Ngày kết thúc',
            key: 'endDate',
            sortable: false,
            class: 'text-center',
            style: {
              width: '15%',
            },
          },
          {
            label: '',
            key: 'action',
            sortable: false,
            class: 'text-center',
            style: {
              width: '5%',
            },
          },
        ];
      }
      return [
        {
          label: 'Khách hàng',
          class: 'text-left',
          key: 'name',
          sortable: true,
          style: {
            width: '30%',
          },
        },
        {
          label: 'Tuổi',
          key: 'age',
          sortable: false,
          class: 'text-center',
          style: {
            width: '5%',
          },
        },
        {
          label: 'ĐTĐ Tip',
          key: 'tip',
          sortable: false,
          class: 'text-center',
          style: {
            width: '10%',
          },
        },
        {
          label: 'Số điện thoại',
          key: 'phoneNumber',
          sortable: false,
          class: 'text-center',
          style: {
            width: '10%',
          },
        },
        {
          label: 'Ngày bắt đầu',
          key: 'startDate',
          sortable: false,
          class: 'text-center',
          style: {
            width: '15%',
          },
        },
        {
          label: 'Ngày kết thúc',
          key: 'endDate',
          sortable: false,
          class: 'text-center',
          style: {
            width: '15%',
          },
        },
        {
          key: 'mapping',
          label: 'Tiêu chí phù hợp',
          sortable: false,
          class: 'text-center',
          style: {
            width: '15%',
          },
        },
        {
          label: '',
          key: 'action',
          sortable: false,
          class: 'text-center',
          style: {
            width: '5%',
          },
        },
      ];
    },
  },
  watch: {
    paging: {
      handler() {
      
      },
      deep: true,
    },
  },

  methods: {
    searchRequest() {
      this.$emit('search');
    },
    select(params) {
      this.$emit('select', params);
    },
    deletePatient(patient) {
      this.$emit('delete', patient);
    },
    sortRequest(params) {
      this.$emit('sortBy', params);
    },
    changeGroup(item) {
      this.$emit('change-group', item);
    },
    openModalMapping(item) {
      this.$emit('open-modal-mapping', { type: 'customer', item });
    },
   
async viewDashboard(item) {
  try {
    const { data } = await this.$api.get('/UserDashboard/Patient', {
      params: {
        filterStr: item.phoneNumber,
        page: 1,
        size: 1 
      }
    });

    const id = data.items[0].id;
    this.$router.push({
      name: 'user_dashboard',
      params: {
        id
      }
    });
  } catch (error) {
    console.error('Error retrieving patient data:', error);
  }
}


    
    

  },
};
</script>
<style lang="scss">
.checkbox > span {
  background-color: #fff;
  border: 1px solid #b5b5c3;
}
</style>
